.skills {
    width: 100%;
    padding: 50px;
}

.skills-container {
    border-radius: 10px;
    padding: 20px;
    border: 1px solid var(--color-secondary)
}

.tabs-header {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    border: 1px solid var(--color-secondary);
    border-radius: 10px;
    padding-top: 10px;
}

.tab {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    border: 2px solid var(--color-text-bold);
    border-style: solid solid none solid;
    border-radius: 10px 10px 0 0;
    margin: 0 5px;

}

.tab:hover {
    background-color: var(--color-primary-accent);
    opacity: 0.5;
    color: var(--color-white);
}

ul.react-tabs__tab-list {
    display: flex;
    border: none;
    margin: 0 0 10px;
    padding: 0;
    font-size: var(--font-h5);
    font-weight: bold;
    color: var(--color-text-primary);
}

li.react-tabs__tab--selected {
    background-color: var(--color-primary-accent);
    color: var(--color-white);
}

.skills-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin-bottom: 40px;
}

.skill-card {
    width: 10vw;
    border: 1px solid var(--color-tertiary);
    border-radius: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    transition: all 0.3s ease;
}

.skill-card:hover {
    transform: scale(1.05);
    box-shadow: 0 0 5px var(--color-primary-accent);
    text-shadow: 0 0 2px var(--color-text-secondary);
}

.skill-card img {
    width: 100px;
    height: 100px;
}

.skill-card a {
    margin-top: 10px;
    color: var(--color-text-secondary);
    text-decoration: none;
}

.skill-card a:hover {
    color: var(--color-primary-accent);
    transform: scale(1.1);
}


.progress-bar {
    width: 100%;
    background-color: var(--color-white);
    border-radius: 5px;
}

.progress {
    height: 20px;
    background-color: var(--color-primary-accent);
    border-radius: 5px;
}

@media (max-width: 1024px) {

    .tab {
        padding: 10px 15px;
    }

    .skills {
        padding: 30px;
    }

    .skill-card {
        width: 15vw;
    }
}

@media (max-width: 768px) {
    .tab {
        padding: 10px 10px;

    }

    .tabs-header {
        margin: 0;
        padding: 0.4rem 0 0 0;
    }

    .tabs-header ul li {
        padding: 0.3rem;
        font-size: 0.8rem;
        text-align: center;
    }

    .skills-container {
        padding: 0;
    }

    .skills {
        padding: 20px;
    }

    .skills-grid {
        gap: 10px;
    }

    .skill-card {
        width: 20vw;
    }

    .skill-card img {
        width: 80px;
        height: 80px;
    }

    .skill-card h4 {
        font-size: var(--font-h5);
    }

    .skill-card a {
        font-size: var(--font-mini);
    }
}
nav {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10vh;
    font-size: 1.2rem;
    position: fixed;
    top: 20px;
    width: 100%;
    background-color: transparent;
    z-index: 9999;

}

.navbar {
    background-color: transparent;
    transition: background-color 0.5s ease;
    text-align: center;
    justify-content: space-between;
    align-items: center;
    display: flex;
    list-style-type: none;
    border-radius: 50px;
    box-sizing: border-box;
    padding: 0.3rem;
}

.navbar li {
    padding: 0 0.6rem;
    background-color: transparent;
}

.navbar-brand {
    font-size: var(--font-link);
    background-color: transparent;
    color: var(--color-text-secondary);
    display: table;
    padding: 0.4rem 0.6rem;
    transition: background-color 0.3s ease;
    border-radius: var(--radius-base);
    margin: 0.3rem 0;
}

.navbar-brand:hover {
    animation: shake 0.5s;
}

.navbar-brand.active {
    background: var(--color-primary-accent);
    color: var(--color-text-tertiary);
    border-radius: var(--radius-base);
}

.navbar.scrolled {
    background-color: var(--color-backdrop);
    backdrop-filter: blur(20px) saturate(1.7);
    z-index: 9999;
}

.navbar.scrolled.dark {
    background-color: #233831;
    background-color: rgba(0, 0, 0, 0.3);
}

.content {
    height: 2000px; /* Placeholder pour du contenu de la page */
}


@keyframes shake {
    0% {
        transform: translate(0px, 1px);
    }

    25% {
        transform: translate(0px, -1px);
    }

    50% {
        transform: translate(0px, 1px);
    }

    75% {
        transform: translate(0px, -1px);
    }

    100% {
        transform: translate(0px, 1px);
    }
}

/*@media screen and (max-width: 1024px) {*/
/*    .navbar {*/
/*        width: 80%*/
/*    }*/
/*    .sc-dmyCSP {*/
/*        width: 80%;*/
/*    }*/

/*    .navbar li {*/
/*        padding: 0;*/
/*    }*/

/*    .navbar-brand {*/
/*        font-size:var(--font-link);*/
/*    }*/

/*    .navbar-brand.active {*/
/*        background: var(--color-primary-accent);*/
/*        color: var(--color-text-tertiary);*/
/*        border-radius: 50px;*/
/*    }*/
/*}*/

@media screen and (max-width: 768px) {
    .navbar {
        width: 100%;
    }

    .sc-dmyCSP {
        width: 80%;
    }

    .navbar li {
        padding: 0;
    }

    .navbar-brand {
        margin: 0;
    }


    .navbar-brand {
        /*font-size: 0.6rem;*/
    }

    .navbar-brand.active {
        background: var(--color-primary-accent);
        color: var(--color-text-tertiary);
        border-radius: 50px;
    }
}


.image-slider {
    width: 80%;
    text-align: center;
    margin: 5vh auto auto auto;
}

.image-slider .slick-dots {
    bottom: -30px;
}

.image-slider img {
    width: 100%;
    height: 100%;
}

.slick-prev:before, .slick-next:before {
    color: var(--color-text-secondary);
}

.slick-dots li button:before {
    color: var(--color-text-secondary);
}

.slick-dots li.slick-active button:before {
    color: var(--color-text-primary);
}
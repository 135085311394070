.project-item {
    background: var(--color-white30);
    backdrop-filter: blur(10px) saturate(1.3);
    color: var(--color-text-tertiary);
    padding: var(--42px);
    align-items: center;
    justify-content: center;
    border: 1px solid #ccc;
    border-radius: 50px;
    margin: 10px 15px 15px 15px;
    width: 45vw; /* Adjust this value as needed */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    text-align: center;
    transition: all 0.3s ease-in-out;
    transform: scale(0.75);
}


.project-item:hover {
    box-shadow: 0 20px 20px 20px rgba(0, 0, 0, 0.03);
    transition: all 0.3s ease-in-out;
}

.project-item img {
    width: 100%;
    height: 15rem;
    object-fit: cover;
    border-radius: 5px;
    margin-bottom: 10px;
}

.project-item h3 {
    margin: 2.5rem 1rem 0rem 1rem;
}

.project-item p {
    padding: 0 0 1rem 0;
    text-align: justify;
}

.large {
    width: 30vw;
}

.title.language {
    align-self: flex-start;
    display: flex;
}

.languages {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
}

.languages.icons {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.tools {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: flex-end;
    align-self: flex-end;
    margin-right: -0.70rem;
    padding: 0.5rem 0 0.5rem 0;
}

.icons img {
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 1rem;
}

.icons p {
    padding: 0;
    font-size: 1rem;
    color: var(--color-text-primary);
}

p.button {
    display: flex;
    padding: 1rem 0;
    align-self: center;
    justify-content: center;

}

p.button:hover {
    cursor: pointer;
    background: var(--color-primary-accent);
    color: var(--color-white);
    border-radius: 100px;
    transition: all 0.3s ease-in-out;
}

@media (max-width: 1024px) {
    .project-item {
        width: 90%;
    }
}

@media (max-width: 768px) {
    .project-item {
        width: 90%;
    }
}
/* ProjectItemShort.css */
.project-item-short {
    display: grid;
    grid-template-areas:
    "title logo"
    "description logo"
    "image image";
    grid-gap: 1rem;
    justify-content: space-between;
    background-color: var(--color-backdrop);
    backdrop-filter: blur(10px) saturate(1.3);
    padding: var(--42px);
    border-radius: var(--radius-base);
    border: 2px solid var(--color-secondary);
    cursor: pointer;
    transition: all 0.3s ease !important;
}

.project-item-short:hover {
    box-shadow: 0 0 10px var(--color-text-primary);
    transform: translateY(-4px);
}

.project-item-short h3 {
    grid-area: title;
}

.project-item-short .logo {
    grid-area: logo;
    border-radius: var(--radius-base);
}

.project-item-short p:first-of-type {
    grid-area: description;
}

.project-item-short .logo, .project-item-short .image {
    width: 100px; /* Adjust as needed */
    height: auto;
    object-fit: cover;
}

.project-item-short .image {
    grid-area: image;
    width: 100%;
    height: auto;
    border-radius: var(--radius-base);
}

.project-item-short .image.large {
    width: 90%;
    height: auto;
    display: flex;
    align-self: center;
    justify-self: center;
}

.project-item-short.double-grid-row {
    grid-row: span 2;
}

@media screen and (max-width: 768px) {
    .project-item-short {
        grid-template-areas:
        "title logo"
        "description logo"
        "image image";
    }

    .project-item-short .logo, .project-item-short .image {
        width: 80px; /* Adjust as needed */
    }

    .project-item-short .image {
        width: 100%;
    }

    .project-item-short .image.large {
        width: 90%;
    }
}

@media screen and (max-width: 480px) {
    .project-item-short {
        grid-template-areas:
        "title"
        "logo"
        "description"
        "image";
    }

    .project-item-short .logo, .project-item-short .image {
        width: 60px; /* Adjust as needed */
    }

    .project-item-short .image {
        width: 100%;
    }

    .project-item-short .image.large {
        width: 90%;
    }
}


.projects {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    padding: 0;
}

.projects-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0;
}

a.buttonAllProjects {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

a.buttonAllProjects strong {
    padding: 1rem 3rem;
    border-radius: 10px;
    background-color: var(--color-secondary);
    color: var(--color-white);
    font-size: 1.5rem;
    text-align: center;
    transition: all 0.3s ease;
}

a.buttonAllProjects strong:hover {
    background-color: var(--color-text-primary);
    color: var(--color-text-tertiary);
    transform: translateY(-4px);
    box-shadow: 0 0 10px var(--color-text-primary);

}
.occupation-container {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    flex-direction: column;
}

.occupation-container h1 {
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.occupation-info {
    display: flex;
    width: 60vw;
    margin: auto;
    justify-content: space-around;
    font-size: var(--font-link);
}

.occupation-header-main {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 15vw;
}


.occupation-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 40vw;
}

.occupation-description {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: auto;
    width: 50vw;
    margin-top: 1rem;
}

.occupation-projects ul {
    margin-left: 2rem;
    margin-top: 1rem;
    list-style: '- ';
    padding: 0;
    font-size: var(--font-link);
    color: var(--color-text-secondary);
}

.occupation-projects ul li {
    margin-top: 0.5rem;
    cursor: pointer;
    box-shadow: inset 0 0 0 0 var(--color-text-primary);
    transition: color .5s ease-in-out, box-shadow .5s ease-in-out;
    width: fit-content;
}

.occupation-projects ul li:hover {
    /*Box shadow size of the liste element*/
    box-shadow: inset 100vw 0 0 0 var(--color-text-primary);
    color: var(--color-text-tertiary);
}

.occupation-projects p:hover {
    color: var(--color-text-primary);
    cursor: pointer;
}

.skills-tools {
    justify-content: space-between;
}

.title {
    justify-content: start;
}

.back {
    margin-top: 2rem;
}

.back button {
    padding-top: 2rem;
    padding-bottom: 2rem;
    border-radius: var(--radius-base);
    background: var(--color-opaque);
    border: 2px solid var(--color-text-primary);
    font-size: var(--font-link);
    color: var(--color-text-primary);
    transition: background-color 0.3s ease;
    cursor: pointer;
    width: 25%;
    margin: 2rem auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: var(--font-h2);
}

.back button:hover {
    background-color: var(--color-text-primary);
    color: var(--color-text-tertiary);
}

@media (max-width: 1024px) {
    .occupation-info {
        width: 90vw;
    }

    .occupation-description {
        width: 90vw;
    }

    .occupation-projects ul {
        margin-left: 1rem;
    }

    .occupation-projects ul li {
        font-size: var(--font-h5);
    }

    .back button {
        width: 50%;
    }
}

@media screen and (max-width: 768px) {
    .occupation-info {
        flex-direction: column;
        gap: 1rem;
    }

    .occupation-header-main {
        max-width: 90vw;
    }

    .occupation-content {
        max-width: 90vw;
    }

    .occupation-description {
        width: 90vw;
    }

    .occupation-projects ul {
        margin-left: 1rem;
    }

    .occupation-projects ul li {
        font-size: var(--font-h5);
    }

    .back button {
        width: 50%;
    }
}
/* AllProjects.css */
.all-projects-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: auto;
    grid-gap: 1rem;
    padding: 3rem;
}

.project-item {
    grid-row: span 2;
}

/* ProjectItem.css */
.project-item img {
    width: 100%;
    height: auto; /* Change this to auto to maintain aspect ratio */
    object-fit: cover;
    border-radius: 5px;
    margin-bottom: 10px;
}

.project-item-main {
    width: 30vw;
    height: auto;
}

@media screen and (max-width: 768px) {
    .all-projects-container {
        grid-template-columns: repeat(2, 1fr);
    }

    .project-item-main {
        width: 40vw;
    }
}

@media screen and (max-width: 480px) {
    .all-projects-container {
        grid-template-columns: repeat(1, 1fr);
    }

    .all-projects-container {
        padding: 1rem;
    }

    .project-item-main {
        width: 90vw;
    }
}

.career-job-item {
    grid-gap: 1rem;
    justify-content: space-between;
    background-color: var(--color-backdrop);
    backdrop-filter: blur(10px) saturate(1.3);
    padding: var(--42px);
    border-radius: var(--radius-base);
    border: 2px solid var(--color-secondary);
    cursor: pointer;
    width: 60vw;
    margin: 0 auto 2rem auto;
    transition: all 0.3s ease !important;
}

.career-job-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.career-job-item:hover {
    background-color: var(--color-backdrop);
    backdrop-filter: blur(15px) saturate(1.3) brightness(2);
    transform: scale(1.2);
    box-shadow: 0 0 10px 0 var(--color-secondary);
}

@media (max-width: 1024px) {
    .career-job-item {
        width: 90vw;
    }
}

@media screen and (max-width: 768px) {
    .career-job-item {
        width: 90vw;
    }
}
* {
	background-color: transparent;
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'League Spartan Regular', sans-serif;
	transition: all 0.5s ease;
}

h1 {
	color: var(--color-text-primary);
	font-size: var(--font-h1);
	font-family: "Arcon Bold", serif;
	margin: 5rem 0 2rem 0;
	padding: 0 2rem;
}

h2 {
	font-size: var(--font-h2);
	color: var(--color-text-primary);
	font-family: "Arcon Bold", serif;
}

h3 {
	font-size: 2.5rem;
	color: var(--color-text-primary);
	font-family: "Arcon Bold", serif;
}

h4 {
	font-size: 2rem;
	color: var(--color-text-primary);
	font-family: "League Spartan SemiBold", sans-serif;
}

h5 {
	font-size: 1.5rem;
	color: var(--color-text-primary);
	font-family: "League Spartan Thin", sans-serif;

}

html {
	scroll-behavior: smooth;
}

strong {
	font-weight: bold;
}

a {
	text-decoration: none;
	color: var(--color-text-secondary);
}

a[href*="http"] {
	padding-right: 19px;
	position: relative;
}

a[href*="http"]::after {
	content: "↗";
	position: absolute;
	right: 0;
	top: 0;
}

p {
	font-size: var(--font-body);
	color: var(--color-text-secondary);
	text-align: justify;
}

button {
	font-family: 'League Spartan Bold', sans-serif;
	font-size: 1.5rem;
	color: var(--color-text-active);
	background-color: #344543;
	border: none;
	border-radius: 20px;
	padding: 1rem 2rem;
	margin: 1rem;
	cursor: pointer;
	transition: all 0.5s ease;
}

button:hover {
	background-color: var(--color-text-primary);
	transition: all 0.5s ease;
}

body {
	background-color: var(--color-primary);
	line-height: 1.25;
}

p li {
	margin: 8px 0 8px 4rem;
}

@font-face {
	font-family: 'League Spartan ExtraLight';
	src: local("League Spartan"), url('../public/fonts/LeagueSpartan/LeagueSpartan-ExtraLight.ttf') format('truetype');
}

@font-face {
	font-family: 'League Spartan Light';
	src: local("League Spartan"), url('../public/fonts/LeagueSpartan/LeagueSpartan-Light.ttf') format('truetype');
}

@font-face {
	font-family: 'League Spartan Regular';
	src: local("League Spartan"), url('../public/fonts/LeagueSpartan/LeagueSpartan-Regular.ttf') format('truetype');
}

@font-face {
	font-family: 'League Spartan Bold';
	src: local("League Spartan"), url('../public/fonts/LeagueSpartan/LeagueSpartan-Bold.ttf') format('truetype');
}

@font-face {
	font-family: 'League Spartan Black';
	src: local("League Spartan"), url('../public/fonts/LeagueSpartan/LeagueSpartan-Black.ttf') format('truetype');
}

@font-face {
	font-family: 'League Spartan ExtraBold';
	src: local("League Spartan"), url('../public/fonts/LeagueSpartan/LeagueSpartan-ExtraBold.ttf') format('truetype');
}

@font-face {
	font-family: 'League Spartan SemiBold';
	src: local("League Spartan"), url('../public/fonts/LeagueSpartan/LeagueSpartan-SemiBold.ttf') format('truetype');
}

@font-face {
	font-family: 'League Spartan Thin';
	src: local("League Spartan"), url('../public/fonts/LeagueSpartan/LeagueSpartan-Thin.ttf') format('truetype');
}


.career-container {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    transition: all 0.5s ease;
}

.career-container::after {
    content: '';
    position: absolute;
    width: 6px;
    background-color: var(--color-primary-accent);
    top: 0;
    bottom: 0;
    left: 50%;
    margin-left: -3px;

}

.job-item {
    padding: var(--42px);
    position: relative;
    border: 2px solid var(--color-primary-accent);
    border-radius: 10px 0 0 10px;
    block-size: 100%;
    width: 50%;
    box-sizing: border-box;
    background-color: var(--color-backdrop);
    backdrop-filter: blur(10px) saturate(1.3);
}


.job-item::after {
    content: '';
    position: absolute;
    width: 25px;
    height: 25px;
    right: -17px;
    background-color: white;
    border: 4px solid #6c757d;
    top: 15px;
    border-radius: 50%;
    z-index: 1;
}

p.job-description {
    padding: 20px !important;
}

.job-item:nth-child(odd) {
    left: 0;
}

.job-item:nth-child(even) {
    left: 50%;
    border-radius: 0 10px 10px 0;
}

.job-item:nth-child(even)::after {
    left: -17px;
}

.job-item:nth-child(odd)::before {
    content: " ";
    height: 0;
    position: absolute;
    top: 22px;
    width: 0;
    z-index: 1;
    right: 30px;
    border: medium solid white;
    border-width: 10px 0 10px 10px;
    border-color: transparent transparent transparent white;
}

.job-item:nth-child(even)::before {
    content: " ";
    height: 0;
    position: absolute;
    top: 22px;
    width: 0;
    z-index: 1;
    left: 30px;
    border: medium solid white;
    border-width: 10px 10px 10px 0;
    border-color: transparent white transparent transparent;
}




.job-item h2, .job-item .date, .job-item p {
    padding: 0 20px;
}

.job-item .skills {
    padding: 0 20px;
    display: flex;
    flex-wrap: wrap;
}

.job-item img {
    width: 100%;
}

.icons {
    display: flex;
    margin-right: 10px;
    padding: 5px;
    border: 1px solid #ced4da;
    border-radius: 5px;
}

.icons p {
    margin: 0;
    font-size: 1rem;
}

.image-container {
    position: relative;
}

.image-container img {
    width: 100%;
    border-radius: 10px;
}

.image-container .image-title {
    position: absolute;
    bottom: 20px;
    left: 20px;
    background-color: var(--color-primary-accent);
    border-radius: 10px;
    padding: 10px;
    visibility: hidden;
    opacity: 0;
    transition: all 0.5s ease;
    transform: translateY(50%);
    color: var(--color-text-tertiary);
}

.image-container:hover .image-title {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
}

@media (max-width: 768px) {
    .career-container::after {
        display: none;
    }

    .job-item {
        width: 100%;
    }

    .job-item::after {
        display: none;
    }

    .job-item:nth-child(odd)::before {
        display: none;
    }

    .job-item:nth-child(even)::before {
        display: none;
    }

    .job-item:nth-child(odd) {
        left: 0;
    }

    .job-item:nth-child(even) {
        left: 0;
    }

    .job-item:nth-child(even)::after {
        left: 0;
    }

    .job-item-right {
        left: 0;
    }
}

.contributors {
    display: flex;
    gap: 1rem;
}

.contributor {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contributor img {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: 3px solid var(--color-text-secondary);
    margin-right: 0.25rem;
}

.contributor {
    position: relative; /* Ajoutez cette ligne */
}

.contributor-name {
    font-size: 1rem;
}

.contributor .contributor-name {
    position: absolute;
    bottom: 55px;
    background-color: var(--color-primary-accent);
    border-radius: 10px;
    padding: 10px;
    visibility: hidden;
    opacity: 0;
    transition: all 0.5s ease;
    transform: translateY(50%);
    color: var(--color-text-tertiary);
    white-space: nowrap;
    z-index: 1;
}


.contributor:hover .contributor-name {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
}

.contributor a[href*="http"] {
    padding-right: 0;
}

.contributor a[href*="http"]::after {
    content: "";
    right: 0;
    top: 0;
}
.project-header {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    flex-direction: row;
}

.project-header h1 {
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.project-info {
    display: flex;
    width: 70%;
    margin: auto; /* Ajouté pour centrer le bloc */
    justify-content: space-around; /* Déjà présent dans votre code */
    font-size: var(--font-link);
}

.project-header-main {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 15vw;
}

.contributorsimg {
    display: flex;
}


.colorprimary {
    text-align: start;
    box-shadow: inset 0 0 0 0 var(--color-text-primary);
    color: var(--color-text-primary);
    transition: color .5s ease-in-out, box-shadow .5s ease-in-out;
    font-size: var(--font-link);
}

.project-header-contains-link {
    text-align: start;
    box-shadow: inset 0 0 0 0 var(--color-text-primary);
    color: var(--color-text-primary);
    transition: color .5s ease-in-out, box-shadow .5s ease-in-out;
    font-size: var(--font-link);
}

.project-header-contains-link:hover {
    box-shadow: inset 100vw 0 0 0 var(--color-text-primary);
    color: var(--color-text-tertiary);
}


.project-header p {
    margin: 0;
}

.project-header-website-link {
    display: flex;
}

.project-description {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: auto;
    width: 50vw;
    margin-top: 1rem;
}

.description {
    margin-top: 3rem;
}

.project-description h3 {
    margin-bottom: 2rem;
}

.project-images {
    padding: 2rem;
    margin-top: 2rem;
}

.images {
    background: var(--color-opaque);
    padding: 2rem;
    border-radius: var(--radius-base);
}

.images h2 {
    width: 50vw;
    margin: auto;
}

.project-video {
    padding: 2rem;
    margin-top: 2rem;
}

.project-video h2 {
    width: 50vw;
    margin: auto;
}

.video {
    margin: auto;
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
    width: 50%;
}

.project-skills {
    background: var(--color-opaque);
    padding: 2rem;
    width: 70vw;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: auto;
    margin-top: 2rem;
    border-radius: var(--radius-base);
}

.project-skills h2 {
    width: 50vw;
    margin: auto;
}

.back {
    margin-top: 2rem;
}

.back-div {
    display: flex;
    justify-content: center;
    align-items: center;

}

button.back {
    background: var(--color-opaque);
    font-size: var(--font-link);
    color: var(--color-text-primary);
    border-radius: var(--radius-base);
    border: 1px solid var(--color-primary-border);
    cursor: pointer;
    transition: background-color .5s ease-in-out, color .5s ease-in-out;
}

button.back:hover {
    background-color: var(--color-text-primary);
    color: var(--color-text-tertiary);
}

@media (max-width: 1024px) {
    .project-info {
        flex-direction: column;
        gap: 1rem;
    }

    .project-header-main {
        max-width: 100%;
    }

    .project-description {
        width: 90vw;
    }

    .project-skills {
        width: 90vw;
    }

}